import React from 'react';
import * as Styled from './MobileUnderConstructionPage.styled';

export const MobileUnderConstructionPage = ({
  companySettings,
  socialMediaSettings,
}) => {
  return (
    <>
      <Styled.Background />
      <Styled.TopSection>
      <Styled.Frame />
        <Styled.BeautifulGirlPicture>
          <img
            srcSet="
            /beautiful-girl-mobile@2x.png 2x,
            /beautiful-girl-mobile@3x.png 3x"
            src="/beautiful-girl-mobile.png"
            alt="beauty"
          />
        </Styled.BeautifulGirlPicture>
        <Styled.Card>
          <Styled.Logo
            href={process.env.DOMAIN_URL}
            title="Holistic Karolina Prus"
          >
            <img src="/logo.svg" alt="Holistic Karolina Prus logo" />
          </Styled.Logo>
          <Styled.Title>Strona w budowie</Styled.Title>
          <Styled.Description>
            Obecnie trwają zabiegi upiększające dla naszej strony www
          </Styled.Description>
        </Styled.Card>
      </Styled.TopSection>
      <Styled.Separator />
      <Styled.BottomSection>
        <Styled.ContactCard>
          <Styled.Headline>Zadzwoń do nas</Styled.Headline>
          <a
            href={`tel:${companySettings.companyPhone}`}
            title="Skontaktuj się z nami"
          >
            <img src="/phone.svg" alt="telefon" />
          </a>
          <Styled.Phone>
            tel.
            <a
              href={`tel:${companySettings.companyPhone}`}
              title="Skontaktuj się z nami"
            >
              {companySettings.companyPhone}
            </a>
          </Styled.Phone>
          <Styled.Headline>lub zobacz co u nas</Styled.Headline>
          <Styled.SocialMediaWrapper>
            <a
              href={socialMediaSettings.socialMediaFacebook}
              title="Nasz profil na facebooku"
            >
              <img src="/fb.svg" alt="Holistic Karolina Prus na Facebook" />
            </a>
            <a
              href={socialMediaSettings.socialMediaInstagram}
              title="Nasz profil na instagramie"
            >
              <img src="/insta.svg" alt="Holistic Karolina Prus na Instagram" />
            </a>
          </Styled.SocialMediaWrapper>
        </Styled.ContactCard>
      </Styled.BottomSection>
    </>
  );
};
