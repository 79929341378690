import React from 'react';
import styled from 'styled-components';
import { getColor } from '@/theme'

export const Background = styled.div`
  background-image: url('/bcg-3.png');
  background-size: cover;
  background-repeat: no-repeat;
  max-height: 100%;
  height: 100%;
  width: 100%;
  position: absolute;
`;

export const Container = styled.div`
  position: relative;
  max-width: 1140px;
  margin: 0 auto;
  height: 100%;
`;

export const Frame = styled.div`
  position: absolute;
  width: 1120px;
  height: 372px;
  max-width: 100%;
  top: -16px;
  right: 16px;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 20px;
    padding: 2px;
    background: linear-gradient(
      to top,
      #fbe180,
      #d9ae3c,
      #ffe880,
      #fae077,
      #eece61,
      #e4bf50,
      #deb545,
      #dab03e,
      #d9ae3c,
      #d9ae3c
    );
    -webkit-mask: linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: destination-out;
    mask-composite: exclude;
  }
`;

export const Card = styled.div`
  text-align: center;
  width: 426px;
  height: 340px;
  padding: 24px 76px 24px 72px;
  border-radius: 20px;
  box-shadow: 0 5px 12px 0 rgba(0, 106, 78, 0.15),
    0 1px 8px -4px rgba(0, 106, 78, 0.2);
  background-color: ${getColor('basicWhite')};
`;

export const Title = styled.h1`
  font-family: 'Playfair Display', serif;
  font-size: 56px;
  text-align: right;
  line-height: 1.07;
  font-weight: normal;
  letter-spacing: normal;
  text-shadow: 0 25px 24px rgb(206 206 206 / 50%);
  margin-bottom: 26px;
  color: ${getColor('primaryGrey')};
`;

export const Description = styled.p`
  font-size: 16px;
  line-height: 1.5;
  text-align: right;
  color: ${getColor('primaryGrey')};
`;

export const Phone = styled.p`
  margin: 16px 0 24px;
`;

export const Logo = styled.a`
  position: relative;
  margin-bottom: 35px;
`;

export const Headline = styled.p`
  font-size: 24px;
  font-weight: 600;
  text-align: center;
  color: ${getColor('primaryGrey')};
  margin-bottom: 10px;
`;

export const SocialMediaWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 177px;
  margin: 0 auto;
`;

export const BeautifulGirlPicture = styled.picture`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;

  img {
    object-fit: contain;
    max-height: 100%;
  }
`;

export const CardInnerWrapper = styled.div`
  width: 100%;
  align-items: flex-end;
  display: flex;
  gap: 24px;
  flex-direction: column;
  position: relative;
`;

export const CardOuterWrapper = styled.div`
  height: 100%;
  width: 100%;
  right: 0;
  display: flex;
  gap: 16px;
  justify-content: center;
  padding: 65px 0 20px;
  flex-direction: column;
`;
