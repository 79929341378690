import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { path } from 'ramda';
import { Media } from '@/theme';
import { DesktopUnderConstructionPage } from './DesktopUnderConstructionPage';
import { MobileUnderConstructionPage } from './MobileUnderConstructionPage';

export const UnderConstructionPage = () => {
  const { companySettings, socialMediaSettings } = path(
    ['allWp', 'nodes', '0'],
    useStaticQuery(
      graphql`
        query underConstructionPage {
          allWp {
            nodes {
              socialMediaSettings {
                socialMediaFacebook
                socialMediaInstagram
              }
              companySettings {
                companyPhone
              }
            }
          }
        }
      `
    )
  );

  return (
    <>
      <Media at="sm">
        <MobileUnderConstructionPage
          companySettings={companySettings}
          socialMediaSettings={socialMediaSettings}
        />
      </Media>
      <Media greaterThan="sm">
        <DesktopUnderConstructionPage
          companySettings={companySettings}
          socialMediaSettings={socialMediaSettings}
        />
      </Media>
    </>
  );
};
