import React from 'react';
import { CoreLayout } from '@/layouts/CoreLayout'
import { useStaticQuery, graphql } from 'gatsby';
import { path } from 'ramda';
import { UnderConstructionPage } from '@/features/UnderConstructionPage'
import { HomePage } from '@/features/HomePage';

const Index = () => {
  const isUnderConstruction = path(['allWp', 'nodes', '0', 'hkpSettings', 'hkpUnderConstruction'], useStaticQuery(graphql`
    query underConstruction {
      allWp {
        nodes {
          hkpSettings {
            hkpUnderConstruction
          }
        }
      }
    }
  `));

  if (isUnderConstruction) {
    return <UnderConstructionPage />;
  }

  return (
    <HomePage />
  )
}

export default Index;
