import React from 'react';
import { CoreLayout } from '@/layouts/CoreLayout'
import { useStaticQuery, graphql } from 'gatsby';
import { path } from 'ramda';

export const HomePage = () => {

  return (
    <CoreLayout>
      HomePage
    </CoreLayout>
  )
}
