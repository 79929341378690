import styled from 'styled-components';
import { path } from 'ramda';

export const Main = styled.main`
  min-height: calc(
    100vh - ${path(['theme', 'footerHeight'])}px -
      ${path(['theme', 'headerHeight'])}px
  );
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

export const Container = styled.div<{ fullHeight?: boolean }>`
  width: 100%;
  max-width: ${(props) => props.theme.containerMaxWidth}px;
  margin: 0 auto;
  ${(props) => (props.fullHeight ? 'height: 100%;' : '')}

  @media ${path(['theme', 'media', 'tablet'])} {
    max-width: 1650px;
    padding-left: 32px;
    padding-right: 32px;
  }

  @media ${path(['theme', 'media', 'desktop'])} {
    max-width: 1722px;
  }
`;
