import React from 'react';
import styled from 'styled-components';
import { getColor } from '@/theme';

export const Background = styled.div`
  background-image: url('/bcg-3.png');
  background-size: cover;
  background-repeat: no-repeat;
  max-height: 100%;
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: -1;
`;

export const Frame = styled.div`
  position: absolute;
  width: 100%;
  height: 239px;
  top: 20px;
  right: 16px;
  z-index: 1;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 20px;
    padding: 2px;
    background: linear-gradient(
      to top,
      #fbe180,
      #d9ae3c,
      #ffe880,
      #fae077,
      #eece61,
      #e4bf50,
      #deb545,
      #dab03e,
      #d9ae3c,
      #d9ae3c
    );
    -webkit-mask: linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: destination-out;
    mask-composite: exclude;
  }
`;

export const Card = styled.div`
  text-align: center;
  width: 212px;
  height: 223px;
  border-radius: 20px;
  box-shadow: 0 5px 12px 0 rgba(0, 106, 78, 0.15),
    0 1px 8px -4px rgba(0, 106, 78, 0.2);
  background-color: ${getColor('basicWhite')};
  padding: 8px 28px 8px 25px;
`;

export const ContactCard = styled(Card)`
  width: 288px;
  height: 340px;
  padding: 26px 42px 16px;
`;

export const Title = styled.h1`
  font-family: 'Playfair Display', serif;
  font-size: 32px;
  text-align: right;
  line-height: 1.25;
  font-weight: normal;
  letter-spacing: normal;
  text-shadow: 0 25px 24px rgb(206 206 206 / 50%);
  margin-bottom: 10px;
  color: ${getColor('primaryGrey')};
`;

export const Description = styled.p`
  font-size: 14px;
  line-height: 1.71;
  text-align: right;
  color: ${getColor('primaryGrey')};
`;

export const Phone = styled.p`
  margin: 8px 0 24px;
  font-size: 20px;
`;

export const Logo = styled.a`
  position: relative;
  margin-bottom: 0px;
  max-width: 145px;
`;

export const Headline = styled.p`
  font-size: 24px;
  font-weight: 600;
  text-align: center;
  color: ${getColor('primaryGrey')};
  margin-bottom: 17px;
`;

export const SocialMediaWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 177px;
  margin: 0 auto;
`;

export const BeautifulGirlPicture = styled.picture`
  width: 206px;
  height: 292px;

  img {
    position: absolute;
    left: 0;
    top: 0;
  }
`;

export const Separator = styled.div`
  width: 100%;
  height: 4px;
  background-color: ${getColor('primaryGreen100')};
`;

export const TopSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 25px 0 28px;
  height: 292px;
`;

export const BottomSection = styled.div`
  min-height: calc(100vh - 292px - 4px);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px;
`;
