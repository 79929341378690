import React from 'react'
import { Helmet } from 'react-helmet'
import * as Styled from './CoreLayout.styled'
import { DEFAULT_PAGE_TITLE } from '@/consts'
// import { Header } from './Header'
// import { Footer } from './Footer'

export const CoreLayout = ({ children }) => {
  return (
    <>
      <Helmet
        defaultTitle={DEFAULT_PAGE_TITLE}
        titleTemplate={`%s | ${DEFAULT_PAGE_TITLE}`}
      />
      {/*<Header />*/}
      <Styled.Main>{children}</Styled.Main>
      {/*<Footer />*/}
    </>
  )
}
