import React from 'react';
import * as Styled from './DesktopUnderConstructionPage.styled';

export const DesktopUnderConstructionPage = ({
  companySettings,
  socialMediaSettings,
}) => {
  return (
    <>
      <Styled.Background />
      <Styled.BeautifulGirlPicture>
        <img
          srcSet="
            beautiful-girl-desktop@1000w.png 1000w,
            beautiful-girl-desktop@1457w.png 1457w,
            beautiful-girl-desktop@2085w.png 2085w,
            beautiful-girl-desktop@2577w.png 2577w"
          src="beautiful-girl-desktop@2577w.png"
          alt="beauty"
        />
      </Styled.BeautifulGirlPicture>
      <Styled.Container>
        <Styled.CardOuterWrapper>
          <Styled.CardInnerWrapper>
            <Styled.Frame />
            <Styled.Card>
              <Styled.Logo href={process.env.DOMAIN_URL} title="Holistic Karolina Prus">
                <img src="/logo.svg" alt="Holistic Karolina Prus logo" />
              </Styled.Logo>
              <Styled.Title>Strona w budowie</Styled.Title>
              <Styled.Description>
                Obecnie trwają zabiegi upiększające dla naszej strony www
              </Styled.Description>
            </Styled.Card>
            <Styled.Card>
              <Styled.Headline>Zadzwoń do nas</Styled.Headline>
              <a
                href={`tel:${companySettings.companyPhone}`}
                title="Skontaktuj się z nami"
              >
                <img src="/phone.svg" alt="telefon" />
              </a>
              <Styled.Phone>
                tel.
                <a
                  href={`tel:${companySettings.companyPhone}`}
                  title="Skontaktuj się z nami"
                >
                  {companySettings.companyPhone}
                </a>
              </Styled.Phone>
              <Styled.Headline>lub zobacz co u nas</Styled.Headline>
              <Styled.SocialMediaWrapper>
                <a
                  href={socialMediaSettings.socialMediaFacebook}
                  title="Nasz profil na facebooku"
                >
                  <img
                    src="/fb.svg"
                    alt="Holistic Karolina Prus na Facebook"
                  />
                </a>
                <a
                  href={socialMediaSettings.socialMediaInstagram}
                  title="Nasz profil na instagramie"
                >
                  <img
                    src="/insta.svg"
                    alt="Holistic Karolina Prus na Instagram"
                  />
                </a>
              </Styled.SocialMediaWrapper>
            </Styled.Card>
          </Styled.CardInnerWrapper>
        </Styled.CardOuterWrapper>
      </Styled.Container>
    </>
  );
};
